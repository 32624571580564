<template>
  <div v-if="!isFormSubmitted">
    <b-card class="create-site">
      <b-form @submit.prevent>
        <!-- <div class="connecting-line"></div> -->
        <b-tabs
          class="wizard"
          nav-wrapper-class="nav-wrapper"
          v-model="tabIndex"
          no-key-nav
          no-nav-style
          ref="wizard"
        >
          <!-- New Tab Button (Using tabs-end slot) -->
          <b-tab v-if="showFirstStep" title-item-class="disabledTab">
            <template #title>
              <span
                class="step-circle"
                :class="{ completed: tabIndex == 1 || tabIndex == 2 }"
                >1</span
              >
              <span class="step-text">Get site ID</span>
            </template>
            <label for="input-live" class="form-label"
              >Click 'Get Site ID' below to generate a new site ID for the DRSG
              member</label
            >
            <div class="get-site-div">
              <div class="site-input col-md-4 col-sm-12 pl-0 pr-0 mb-3 mb-sm-0">
                <b-form-input
                  id="inline-form-input-name"
                  class="mr-sm-2 mb-sm-0"
                  :class="{ 'is-valid': siteId }"
                  disabled
                  v-model="siteId"
                ></b-form-input>
                <b-form-valid-feedback>
                  <i class="icon-success"></i> Site ID is successfully generated
                </b-form-valid-feedback>
              </div>
              <div class="site-fetch-btn">
                <b-button variant="brand-secondary" @click="getSiteId"
                  >Get Site Id</b-button
                >
              </div>
            </div>

            <div class="d-flex flex-row-reverse">
              <b-button
                @click="navigateToBasicInfo"
                variant="brand-primary"
                v-if="siteId != null"
                >Next</b-button
              >
            </div>
          </b-tab>
          <b-tab title-item-class="disabledTab">
            <template #title>
              <span class="step-circle" :class="{ completed: tabIndex == 2 }"
                >2</span
              >
              <span class="step-text">Basic Information</span>
            </template>
            <div class="site-basicinfo-form">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Site ID"
                    label-class="form-label"
                    label-for="generatedSite"
                  >
                    <span id="generatedSite">{{ siteId }}</span>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Store Brand Id"
                    v-slot="{ ariaDescribedby }"
                    label-class="form-label"
                    label-for="StoreBrandId"
                  >
                    <b-form-radio-group
                      id="StoreBrandId"
                      v-model="siteBasicInfo.StoreBrandId"
                      :options="storeBrandOptions"
                      :aria-describedby="ariaDescribedby"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Shopify shop URL"
                    label-class="form-label required"
                    label-for="shopifyShopURL"
                  >
                    <b-form-input
                      id="shopifyShopURL"
                      v-model="siteBasicInfo.url"
                      placeholder="E.g. http://shopify.plus/business/"
                      :class="{ 'is-invalid': $v.siteBasicInfo.url.$error }"
                    />
                    <b-form-invalid-feedback id="shopifyShopURL-feedback">
                      {{
                        $v.siteBasicInfo.url.url == false
                          ? sytemMessages.enterValidUrl
                          : sytemMessages.shopifyShopURLRequired
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Business name"
                    label-class="form-label required"
                    label-for="businessName"
                  >
                    <b-form-input
                      id="businessName"
                      v-model="siteBasicInfo.name"
                      placeholder="Enter business name"
                      :class="{ 'is-invalid': $v.siteBasicInfo.name.$error }"
                    />
                    <b-form-invalid-feedback id="businessName-feedback">
                      {{ sytemMessages.businessNameRequired }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Member name"
                    label-class="form-label required"
                    label-for="memberName"
                  >
                    <b-form-input
                      id="memberName"
                      v-model="siteBasicInfo.memberName"
                      placeholder="Enter Member name"
                      :class="{
                        'is-invalid': $v.siteBasicInfo.memberName.$error,
                      }"
                      @keypress="allowOnlyAlphabeticWithSpace"
                    />
                    <b-form-invalid-feedback id="memberName-feedback">
                      {{ sytemMessages.memberNameRequired }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Membership Id"
                    label-class="form-label required"
                    label-for="memberShipId"
                  >
                    <b-form-input
                      id="memberShipId"
                      v-model="siteBasicInfo.memberShipId"
                      placeholder="Enter Membership ID"
                      :class="{
                        'is-invalid': $v.siteBasicInfo.memberShipId.$error,
                      }"
                    />
                    <b-form-invalid-feedback id="memberShipId-feedback">
                      {{ sytemMessages.memberShipIdRequired }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Website domain URL"
                    label-class="form-label required"
                    label-for="webSiteDomainURL"
                  >
                    <b-form-input
                      id="webSiteDomainURL"
                      v-model="siteBasicInfo.webSiteDomainURL"
                      placeholder="E.g. Http://business.ca"
                      :class="{
                        'is-invalid': $v.siteBasicInfo.webSiteDomainURL.$error,
                      }"
                    />
                    <b-form-invalid-feedback id="webSiteDomainURL-feedback">
                      {{
                        $v.siteBasicInfo.webSiteDomainURL.url == false
                          ? sytemMessages.enterValidUrl
                          : sytemMessages.webSiteDomainURLRequired
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Primary contact name"
                    label-class="form-label required"
                    label-for="primaryContactEmail"
                  >
                    <b-form-input
                      id="primaryContactName"
                      v-model="siteBasicInfo.primaryContactName"
                      placeholder="Enter primary contact name"
                      :class="{
                        'is-invalid':
                          $v.siteBasicInfo.primaryContactName.$error,
                      }"
                      @keypress="allowOnlyAlphabeticWithSpace"
                    />
                    <b-form-invalid-feedback id="primaryContactName-feedback">
                      {{ sytemMessages.primaryContactNameRequired }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    label="Primary contact email"
                    label-class="form-label required"
                    label-for="primaryContactEmail"
                  >
                    <b-form-input
                      id="primaryContactEmail"
                      v-model="siteBasicInfo.primaryContactEmail"
                      placeholder="Enter primary contact email"
                      :class="{
                        'is-invalid':
                          $v.siteBasicInfo.primaryContactEmail.$error,
                      }"
                    />
                    <b-form-invalid-feedback id="primaryContactEmail-feedback">
                      {{
                        $v.siteBasicInfo.primaryContactEmail.email == false
                          ? sytemMessages.enterValidEmail
                          : sytemMessages.primaryContactEmailRequired
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>

              <div class="d-flex flex-row-reverse">
                <b-button @click="navigateToAPIKeys" variant="brand-primary"
                  >Next</b-button
                >
              </div>
            </div>
          </b-tab>
          <b-tab title-item-class="disabledTab">
            <template #title>
              <span class="step-circle">3</span>
              <span class="step-text">API Keys</span>
            </template>
            <b-alert variant="danger" v-model="showAlert" dismissible>{{
              alertMessage
            }}</b-alert>
            <div
              v-for="(v, index) in $v.siteApikeys.$each.$iter"
              :key="'siteApikeys_' + index"
            >
              <b-form-group
                :label="shopifySiteAppList[index]['label']"
                label-class="form-label required"
                :label-for="shopifySiteAppList[index]['slug']"
              >
                <div
                  :id="shopifySiteAppList[index]['slug']"
                  :class="{
                    'form-group--error':
                      v.$error ||
                      isValidAPIKeys[
                        shopifySiteAppList[index]['validationKeys']
                      ] ||
                      hasPublicationTOAPIKeys[
                        shopifySiteAppList[index]['validationKeys']
                      ],
                    'is-invalid':
                      isValidAPIKeys[
                        shopifySiteAppList[index]['validationKeys']
                      ] ||
                      hasPublicationTOAPIKeys[
                        shopifySiteAppList[index]['validationKeys']
                      ],
                  }"
                >
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <b-form-group
                        label="API Key"
                        :label-for="
                          shopifySiteAppList[index]['slug'] + 'APIKey'
                        "
                      >
                        <b-form-input
                          :id="shopifySiteAppList[index]['slug'] + 'APIKey'"
                          v-model="v.$model.apiKey"
                          :class="{
                            'is-invalid':
                              v.apiKey.$error ||
                              !shopifySiteAppList[index]['validationKeys'],
                          }"
                          autocomplete="off"
                        />
                        <b-form-invalid-feedback
                          :id="
                            shopifySiteAppList[index]['slug'] +
                            'APIKey-feedback'
                          "
                        >
                          {{ sytemMessages.apikeyRequired }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <b-form-group
                        label="Secret Key"
                        :label-for="
                          shopifySiteAppList[index]['slug'] + 'SecretKey'
                        "
                      >
                        <b-form-input
                          :id="shopifySiteAppList[index]['slug'] + 'SecretKey'"
                          v-model="v.$model.apiPassword"
                          :class="{ 'is-invalid': v.apiPassword.$error }"
                          autocomplete="off"
                        />
                        <b-form-invalid-feedback
                          :id="
                            shopifySiteAppList[index]['slug'] +
                            'SecretKey-feedback'
                          "
                        >
                          {{ sytemMessages.secretKeyRequired }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <b-form-group
                        label="Shared Secret Key"
                        :label-for="
                          shopifySiteAppList[index]['slug'] + 'sharedSecret'
                        "
                      >
                        <b-form-input
                          :id="
                            shopifySiteAppList[index]['slug'] + 'sharedSecret'
                          "
                          v-model="v.$model.sharedSecret"
                          :class="{ 'is-invalid': v.sharedSecret.$error }"
                          autocomplete="off"
                        />
                        <b-form-invalid-feedback
                          :id="
                            shopifySiteAppList[index]['slug'] +
                            'sharedSecret-feedback'
                          "
                        >
                          {{ sytemMessages.sharedSecretKeyRequired }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <b-form-group
                        label="Webhook Version"
                        :label-for="
                          shopifySiteAppList[index]['slug'] + 'adminGraphQLPath'
                        "
                      >
                        <b-form-select
                          :id="
                            shopifySiteAppList[index]['slug'] +
                            'adminGraphQLPath'
                          "
                          v-model="v.$model.adminGraphQLPath"
                          :options="adminGraphQLPathOptions"
                          :class="{ 'is-invalid': v.adminGraphQLPath.$error }"
                        ></b-form-select>
                        <b-form-invalid-feedback
                          :id="
                            shopifySiteAppList[index]['slug'] +
                            'adminGraphQLPath-feedback'
                          "
                        >
                          {{ sytemMessages.adminGraphQLPathRequired }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <b-form-invalid-feedback
                  :id="shopifySiteAppList[index]['slug'] + '-feedback'"
                >
                  {{
                    isValidAPIKeys[shopifySiteAppList[index]["validationKeys"]]
                      ? sytemMessages.invalidAPIKeys
                      : sytemMessages.invalidPublicationAccessAPIKeys
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex flex-row-reverse">
              <b-button
                variant="brand-primary"
                @click="handleSave"
                v-if="apiKeysVerified"
                >Submit</b-button
              >
              <b-button
                @click="verifySiteAPIKeys"
                variant="brand-primary"
                class="mr-2"
                v-else
                >Verify</b-button
              >
              <b-button
                @click="navigateToBasicInfo"
                variant="brand-secondary"
                class="mr-2"
                >Back</b-button
              >
            </div>
          </b-tab>
        </b-tabs>
      </b-form>
    </b-card>
  </div>
  <div v-else>
    <b-card class="mt-4">
      <form-success icon="icon-in-progress">
        <h5 class="msg-title">
          New site has been submitted and it’s in progress now.
        </h5>
        <p class="msg-desc m-0">
          Your new site will be created soon. You will get an email when it’s
          been created. You can check the status of this site in the
          <b-link to="/admin/sites">All Sites</b-link>
        </p>
      </form-success>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { required, url, email } from "vuelidate/lib/validators";
import { forEach } from "lodash";
import FormSuccess from "../../../../components/FormSuccess.vue";

export default {
  name: "SiteForm",
  props: {
    showFirstStep: {
      type: Boolean,
    },
  },
  components: {
    FormSuccess,
  },
  data() {
    return {
      tabIndex: 0,
      isFormSubmitted: false,
      siteId: null,
      apiKeysVerified: false,
      alertMessage: "",
      showAlert: false,
      storeBrandOptions: [
        { text: "DFA", value: "DFA" },
        { text: "AFHS", value: "AFHS" },
      ],
      shopifySiteAppList: [
        {
          label: "TDG Kiosk",
          slug: "tdgKiosk",
          validationKeys: "errorTDGKioskKeys",
        },
        {
          label: "TDG PDM Feed",
          slug: "tdgPDMFeed",
          validationKeys: "errorTDGPDMFeedKeys",
        },
        {
          label: "TDG PDM Feed 2",
          slug: "tdgPDM2Feed",
          validationKeys: "errorTDGPDM2FeedKeys",
        },
        {
          label: "TDG PDM Feed 3",
          slug: "tdgPDM3Feed",
          validationKeys: "errorTDGPDM3FeedKeys",
        },
        {
          label: "TDG PDM Feed 4",
          slug: "tdgPDM4Feed",
          validationKeys: "errorTDGPDM4FeedKeys",
        },
        {
          label: "TDG Shopify Code API",
          slug: "tdgShopifyCodeAPI",
          validationKeys: "errorTDGShopifyCodeAPIKeys",
        },
      ],
      siteBasicInfo: {
        siteId: 0,
        StoreBrandId: "DFA",
        url: "",
        name: "",
        memberName: "",
        memberShipId: "",
        webSiteDomainURL: "",
        primaryContactName: "",
        primaryContactEmail: "",
      },
      siteApikeys: [
        {
          apiKey: "",
          apiPassword: "",
          sharedSecret: "",
          appName: "TDG Kiosk",
          adminGraphQLPath: "",
        },
        {
          apiKey: "",
          apiPassword: "",
          sharedSecret: "",
          appName: "TDG PDM Feed",
          adminGraphQLPath: "",
        },
        {
          apiKey: "",
          apiPassword: "",
          sharedSecret: "",
          appName: "TDG PDM Feed 2",
          adminGraphQLPath: "",
        },
        {
          apiKey: "",
          apiPassword: "",
          sharedSecret: "",
          appName: "TDG PDM Feed 3",
          adminGraphQLPath: "",
        },
        {
          apiKey: "",
          apiPassword: "",
          sharedSecret: "",
          appName: "TDG PDM Feed 4",
          adminGraphQLPath: "",
        },
        {
          apiKey: "",
          apiPassword: "",
          sharedSecret: "",
          appName: "TDG Shopify Code API",
          adminGraphQLPath: "",
        },
      ],
      adminGraphQLPathOptions: [],
      isValidAPIKeys: {
        errorTDGKioskKeys: false,
        errorTDGPDMFeedKeys: false,
        errorTDGPDM2FeedKeys: false,
        errorTDGPDM3FeedKeys: false,
        errorTDGPDM4FeedKeys: false,
        errorTDGShopifyCodeAPIKeys: false,
      },
      hasPublicationTOAPIKeys: {
        errorTDGKioskKeys: false,
        errorTDGPDMFeedKeys: false,
        errorTDGPDM2FeedKeys: false,
        errorTDGPDM3FeedKeys: false,
        errorTDGPDM4FeedKeys: false,
        errorTDGShopifyCodeAPIKeys: false,
      },
    };
  },
  watch: {
    siteApikeys: {
      handler: function () {
        this.apiKeysVerified = false;
        this.isValidAPIKeys = {
          errorTDGKioskKeys: false,
          errorTDGPDMFeedKeys: false,
          errorTDGPDM2FeedKeys: false,
          errorTDGPDM3FeedKeys: false,
          errorTDGPDM4FeedKeys: false,
          errorTDGShopifyCodeAPIKeys: false,
        };
        this.hasPublicationTOAPIKeys = {
          errorTDGKioskKeys: false,
          errorTDGPDMFeedKeys: false,
          errorTDGPDM2FeedKeys: false,
          errorTDGPDM3FeedKeys: false,
          errorTDGPDM4FeedKeys: false,
          errorTDGShopifyCodeAPIKeys: false,
        };
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapActions("siteManager", [
      "getNewSiteId",
      "getGraphqlPathVersion",
      "verifySiteApiKeys",
      "manageSite",
    ]),
    async getSiteId() {
      try {
        this.generalSetItem({ resource: "isLoading", payload: true });
        let siteId = await this.getNewSiteId({ userId: this.loggedUserId });
        this.siteId = siteId;
        this.generalSetItem({ resource: "isLoading", payload: false });
      } catch {
        this.generalSetItem({ resource: "isLoading", payload: false });
      }
    },
    navigateToBasicInfo() {
      this.tabIndex = 1;
    },
    navigateToAPIKeys() {
      this.$v.siteBasicInfo.$touch();
      if (this.$v.siteBasicInfo.$invalid) return;

      //reset the validation params for the api keys
      this.$v.siteApikeys.$reset();
      this.isValidAPIKeys = {
        errorTDGKioskKeys: false,
        errorTDGPDMFeedKeys: false,
        errorTDGPDM2FeedKeys: false,
        errorTDGPDM3FeedKeys: false,
        errorTDGPDM4FeedKeys: false,
        errorTDGShopifyCodeAPIKeys: false,
      };
      this.hasPublicationTOAPIKeys = {
        errorTDGKioskKeys: false,
        errorTDGPDMFeedKeys: false,
        errorTDGPDM2FeedKeys: false,
        errorTDGPDM3FeedKeys: false,
        errorTDGPDM4FeedKeys: false,
        errorTDGShopifyCodeAPIKeys: false,
      };
      this.apiKeysVerified = false;
      this.showAlert = false;

      let refined_url = this.siteBasicInfo.url
        .replace(/(?:\/+(\?))/, "$1")
        .replace(/\/+$/, "");
      let refined_webSiteDomainURL = this.siteBasicInfo.webSiteDomainURL
        .replace(/(?:\/+(\?))/, "$1")
        .replace(/\/+$/, "");

      this.siteBasicInfo.url = refined_url;
      this.siteBasicInfo.webSiteDomainURL = refined_webSiteDomainURL;

      this.generalSetItem({ resource: "isLoading", payload: true });
      //get the graphql webhook api versions
      this.getGraphqlPathVersion({ userId: this.loggedUserId })
        .then((response) => {
          this.generalSetItem({ resource: "isLoading", payload: false });
          let option = [];
          let newUrl = this.siteBasicInfo.url;
          // var the_arr = newUrl.split("/");

          // if (the_arr[the_arr.length] == "/") {
          //   the_arr.pop();
          //   newUrl = the_arr.join("/");
          // }

          forEach(response, (_data) => {
            if (_data.isActive == 1) {
              let obj = {
                value: `${newUrl}/admin/api/${_data.version}/graphql.json`,
                text: _data.version,
              };
              option.push(obj);
            }
          });
          this.adminGraphQLPathOptions = option;
          this.tabIndex = 2;
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    verifySiteAPIKeys() {
      this.$v.siteApikeys.$touch();
      if (this.$v.siteApikeys.$invalid) return;

      this.generalSetItem({ resource: "isLoading", payload: true });

      let _siteApikeys = Object.assign([], this.siteApikeys).map((element) => {
        return { ...element, siteId: this.siteBasicInfo.siteId };
      });

      this.verifySiteApiKeys({
        _siteApikeys: _siteApikeys,
        userId: this.loggedUserId,
      })
        .then((response) => {
          this.generalSetItem({ resource: "isLoading", payload: false });

          let responseTdgKioskKeys = response.find(
            (e) => e.result.appName == "TDG Kiosk"
          );
          let responseTDGPDMFeedKeys = response.find(
            (e) => e.result.appName == "TDG PDM Feed"
          );
          let responseTDGPDMFeedKeys2 = response.find(
            (e) => e.result.appName == "TDG PDM Feed 2"
          );
          let responseTDGPDMFeedKeys3 = response.find(
            (e) => e.result.appName == "TDG PDM Feed 3"
          );
          let responseTDGPDMFeedKeys4 = response.find(
            (e) => e.result.appName == "TDG PDM Feed 4"
          );
          let responseTDGShopifyCodeAPIKeys = response.find(
            (e) => e.result.appName == "TDG Shopify Code API"
          );

          this.isValidAPIKeys = {
            errorTDGKioskKeys:
              responseTdgKioskKeys.result["isValidPrivateApp"] == false
                ? true
                : false,
            errorTDGPDMFeedKeys:
              responseTDGPDMFeedKeys.result["isValidPrivateApp"] == false
                ? true
                : false,
            errorTDGPDM2FeedKeys:
              responseTDGPDMFeedKeys2.result["isValidPrivateApp"] == false
                ? true
                : false,
            errorTDGPDM3FeedKeys:
              responseTDGPDMFeedKeys3.result["isValidPrivateApp"] == false
                ? true
                : false,
            errorTDGPDM4FeedKeys:
              responseTDGPDMFeedKeys4.result["isValidPrivateApp"] == false
                ? true
                : false,
            errorTDGShopifyCodeAPIKeys:
              responseTDGShopifyCodeAPIKeys.result["isValidPrivateApp"] == false
                ? true
                : false,
          };

          this.hasPublicationTOAPIKeys = {
            // errorTDGKioskKeys:
            //   responseTdgKioskKeys.result["isPublicationAccess"] == false
            //     ? true
            //     : false,
            errorTDGKioskKeys: false,
            errorTDGPDMFeedKeys:
              responseTDGPDMFeedKeys.result["isPublicationAccess"] == false
                ? true
                : false,
            errorTDGPDM2FeedKeys:
              responseTDGPDMFeedKeys2.result["isPublicationAccess"] == false
                ? true
                : false,
            errorTDGPDM3FeedKeys:
              responseTDGPDMFeedKeys3.result["isPublicationAccess"] == false
                ? true
                : false,
            errorTDGPDM4FeedKeys:
              responseTDGPDMFeedKeys4.result["isPublicationAccess"] == false
                ? true
                : false,
            // errorTDGShopifyCodeAPIKeys:
            //   responseTDGShopifyCodeAPIKeys.result["isPublicationAccess"] ==
            //   false
            //     ? true
            //     : false,
            errorTDGShopifyCodeAPIKeys: false,
          };

          let isValidAPIKeys = Object.values(this.isValidAPIKeys).every(
            (value) => value === false
          );

          let hasPublicationAccess = Object.values(
            this.hasPublicationTOAPIKeys
          ).every((value) => value === false);

          this.apiKeysVerified = isValidAPIKeys && hasPublicationAccess;
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    handleSave() {
      let formData = Object.assign({}, this.siteBasicInfo);
      formData["userId"] = this.loggedUserId;
      formData["shopifySitePrivateAppsList"] = this.siteApikeys.map((item) => ({
        ...item,
        siteId: formData.siteId,
      }));

      this.showAlert = false;

      this.generalSetItem({ resource: "isLoading", payload: true });
      this.manageSite(formData)
        .then((response) => {
          this.generalSetItem({ resource: "isLoading", payload: false });

          if (response == 3) {
            this.showAlert = true;
            this.alertMessage = this.sytemMessages.duplicateName;
            window.scrollTo(0, 0);
          } else if (response == 2) {
            this.showAlert = true;
            this.alertMessage = this.sytemMessages.duplicateUrl;
            window.scrollTo(0, 0);
          } else {
            this.showAlert = false;
            this.isFormSubmitted = true;
          }
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
  },
  validations: {
    siteBasicInfo: {
      url: { required, url },
      name: { required },
      memberName: { required },
      memberShipId: { required },
      webSiteDomainURL: { required, url },
      primaryContactName: { required },
      primaryContactEmail: { required, email },
    },
    siteApikeys: {
      required,
      $each: {
        apiKey: { required },
        apiPassword: { required },
        sharedSecret: { required },
        adminGraphQLPath: { required },
      },
    },
  },
};
</script>
